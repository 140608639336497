import React, {useEffect, useState} from 'react';
import styles from './EnterCodeForm.module.css';

import {Link, useNavigate} from 'react-router-dom';
import InputMask from "react-input-mask";

import {getUser} from "../../utils/getUser";
import {VALIDATION_MESSAGES, CODE_MASK} from "../../config";
import {handleCodeChange} from "../../handlers/UserFieldsHandlers";
import {handleCodeButtonClick} from "../../handlers/ButtonSubmitHandlers";
import {refreshCode} from "../../utils/refreshCode";
import {formatTimeCountdown} from "../../utils/dateTimeFormatting";

const EnterCodeForm = () => {
    const [userCode, setUserCode] = useState('');
    const [isValidCode, setIsValidCode] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [countdown, setCountdown] = useState(300);

    const validationToken = localStorage.getItem('validationToken');
    const navigate = useNavigate();

    useEffect(() => {
        getUser()
            .then((user) => {
                if (user) {
                    navigate('/');
                } else if (!validationToken) {
                    navigate('/login');
                }
                else {
                    refreshCode({
                        setErrorMessage: setErrorMessage,
                    });
                }
            })
    }, [navigate]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        return () => {
            clearInterval(timer);
            if (countdown === 0) {
                window.location.reload();
            }
        };
    }, [countdown]);

    return (
        <div className={styles.enterCodeForm}>
            <h2>Подвердите номер телефона</h2>
            <div className={styles.countdown}>
                Код обновится через: {formatTimeCountdown(countdown)}
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="userCode">Код:</label>
                <InputMask
                    mask={CODE_MASK}
                    alwaysShowMask={true}
                    input={userCode}
                    autocomplete={"one-time-code"}
                    className={!isValidCode ? styles.invalidInput : undefined}
                    onChange={(e) => handleCodeChange(
                        {
                            event: e,
                            setUserCode: setUserCode,
                            setIsValidUserCode: setIsValidCode,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                />
                {!isValidCode &&
                    <div className={styles.errorMessage}>
                        {VALIDATION_MESSAGES.notFullUserCode}
                    </div>
                }
            </div>
            <button
                className={styles.enterCodeButton}
                onClick={() => handleCodeButtonClick({
                    userCode: userCode,
                    setUserCode: setUserCode,
                    setErrorMessage: setErrorMessage,
                    navigate,
                })}
                disabled={!(
                    userCode
                )}
            >
                Подтвердить
            </button>
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
            {!userCode ? (
                <div className={styles.errorMessage}>Заполните все поля</div>
            ) : null}
            <div className={styles.linkContainer}>
                <Link to="/registration"
                      className={styles.registerButton}>
                    Назад к регистрации
                </Link>
            </div>
        </div>
    );
};

export default EnterCodeForm;
