import React, {useEffect, useState} from 'react';
import styles from './EditProfileForm.module.css';

import {Link, useNavigate} from 'react-router-dom';

import {getUser} from "../../utils/getUser";
import {VALIDATION_MESSAGES} from "../../config";
import {
    handleEmailChange,
    handleFirstNameChange,
    handleLastNameChange,
} from "../../handlers/UserFieldsHandlers";
import {handleEditClick} from "../../handlers/ButtonSubmitHandlers";

const EditProfileForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isValidFirstName, setIsValidFirstName] = useState(true);
    const [isValidLastName, setIsValidLastName] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();


    useEffect(() => {
        getUser()
            .then((user_data) => {
                if (user_data) {
                    setFirstName(user_data.first_name);
                    setLastName(user_data.last_name);
                    setEmail(user_data.email);
                    setPhone(user_data.phone);
                } else {
                    localStorage.removeItem("accessToken")
                }
            })
    }, [navigate]);


    return (
        <div className={styles.editForm}>
            <h2>Ваши данные</h2>
            <div className={styles.formGroup}>
                <label htmlFor="firstName">Имя:</label>
                <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={firstName}
                    placeholder="Ваше имя"
                    onChange={(e) => handleFirstNameChange(
                        {
                            event: e,
                            setFirstName: setFirstName,
                            setIsValidFirstName: setIsValidFirstName,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                />
                {!isValidFirstName && (
                    <div className={styles.errorMessage}>{VALIDATION_MESSAGES.badName}</div>
                )}
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="lastName">Фамилия:</label>
                <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={lastName}
                    placeholder="Ваша фамилия"
                    onChange={(e) => handleLastNameChange(
                        {
                            event: e,
                            setLastName: setLastName,
                            setIsValidLastName: setIsValidLastName,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                />
                {!isValidLastName && (
                    <div className={styles.errorMessage}>{VALIDATION_MESSAGES.badName}</div>
                )}
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="email">Почта:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="example@example.com"
                    onChange={(e) => handleEmailChange(
                        {
                            event: e,
                            setEmail: setEmail,
                            setIsValidEmail: setIsValidEmail,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                    className={!isValidEmail ? styles.invalidInput : ''}
                />
                {!isValidEmail && (
                    <div className={styles.errorMessage}>{VALIDATION_MESSAGES.badEmail}</div>
                )}
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="email">Номер телефона:</label>
                <input
                    type="phone"
                    id="phone"
                    name="phone"
                    value={phone}
                    placeholder="example@example.com"
                    disabled={true}
                />
            </div>

            <button
                className={styles.editButton}
                onClick={() => handleEditClick({
                    lastName: lastName,
                    firstName: firstName,
                    email: email,
                    setErrorMessage: setErrorMessage,
                    navigate: navigate
                })}
                disabled={!(firstName &&
                    lastName &&
                    email &&
                    isValidFirstName &&
                    isValidLastName &&
                    isValidEmail
                )}
            >
                Сохранить
            </button>
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
            {!firstName || !lastName  ? (
                <div className={styles.errorMessage}>Заполните все поля</div>
            ) : null}
            <div className={styles.linkContainer}>
                <Link to="/"
                      className={styles.mainLink}>
                    На главную
                </Link>
            </div>
        </div>
    );
};

export default EditProfileForm;
