import React from 'react';
import { BrowserRouter as Router, Route, Routes as Switch } from 'react-router-dom';

import Login from './pages/LoginPage/LoginPage';
import NotFound from './pages/NotFoundPage/NotFoundPage';
import Registration from "./pages/RegistrationPage/RegistrationPage";
import EnterCode from "./pages/EnterCodePage/EnterCodePage";
import EnterPhoneRestorePassword from "./pages/EnterPhoneRestorePasswordPage/EnterPhoneRestorePasswordPage";
import PasswordRestore from "./pages/PasswordRestorePage/PasswordRestorePage";
import Order from "./pages/OrderPage/OrderPage";
import EditProfile from "./pages/EditProfilePage/EditProfilePage";

const Routes = ({ setLoading }) => {
    return (
        <Router>
            <div className="screen">
                <div className="content">
                    <Switch>
                        <Route path="/" element={ <Order /> } />
                        <Route path="/login" element={ <Login /> } />
                        <Route path="/registration" element={ <Registration />  } />
                        <Route path="/validate-phone" element={ < EnterCode /> } />
                        <Route path="/enter-phone" element={ <EnterPhoneRestorePassword /> } />
                        <Route path="/reset-password" element={ <PasswordRestore /> } />
                        <Route path="/edit-profile" element={ <EditProfile /> } />
                        <Route path="*" element={ <NotFound /> } />
                    </Switch>
                </div>
            </div>
        </Router>
    );
}

export default Routes;
