import React from 'react';
import EditProfileForm from "../../components/EditProfileForm/EditProfileForm";
import logo from "../../images/logo.png";

const EditProfilePage = () => {
    return (
        <div className="container window">
            <img width="300vw" src={logo} alt="Logo" />
            <EditProfileForm />
        </div>
    );
}

export default EditProfilePage;
