import React from 'react';
import EnterPhoneRestorePasswordForm
    from "../../components/EnterPhoneRestorePasswordForm/EnterPhoneRestorePasswordForm";
import {useNavigate} from "react-router-dom";
import logo from "../../images/logo.png";

const EnterPhoneRestorePasswordPage = () => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    if (token) {
        navigate('/profile');
    }

    return (
        <div className="container window">
            <img width="300vw" src={logo} alt="Logo" />
            <EnterPhoneRestorePasswordForm />
        </div>
    );
}

export default EnterPhoneRestorePasswordPage;
