import React, {useEffect, useState} from 'react';
import styles from './EnterPhoneRestorePasswordForm.module.css';

import {Link, useNavigate} from 'react-router-dom';
import InputMask from "react-input-mask";

import {getUser} from "../../utils/getUser";
import {VALIDATION_MESSAGES, PHONE_MASK} from "../../config";
import {handlePhoneNumberChange} from "../../handlers/UserFieldsHandlers";
import {handlePhoneRestorePasswordButtonClick} from "../../handlers/ButtonSubmitHandlers";

const EnetPhonePasswordRestoreForm = () => {
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const restoreToken = localStorage.getItem('restoreToken');
    const navigate = useNavigate();

    useEffect(() => {
        getUser()
            .then((user) => {
                if (user) {
                    navigate('/');
                } else if (restoreToken) {
                    navigate('/restore-password');
                }
            })
    }, [navigate]);

    return (
        <div className={styles.phonePasswordRestoreForm}>
            <h2>Восстановление пароля</h2>
            <div className={styles.formGroup}>
                <label htmlFor="phoneNumber">Номер телефона:</label>
                <InputMask
                    mask={PHONE_MASK}
                    alwaysShowMask={true}
                    input={phoneNumber}
                    onChange={(e) => handlePhoneNumberChange(
                        {
                            event: e,
                            setPhoneNumber: setPhoneNumber,
                            setIsValidPhoneNumber: setIsValidPhoneNumber,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                />
                {!isValidPhoneNumber &&
                    <div className={styles.errorMessage}>
                        {VALIDATION_MESSAGES.notFullPhone}
                    </div>
                }
            </div>
            <button
                className={styles.phonePasswordRestoreButton}
                onClick={() => handlePhoneRestorePasswordButtonClick({
                    phone: phoneNumber,
                    setErrorMessage: setErrorMessage,
                    navigate,
                })}
                disabled={!(
                    phoneNumber &&
                    isValidPhoneNumber
                )}
            >
                Отправить код
            </button>
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
            {!phoneNumber ? (
                <div className={styles.errorMessage}>Заполните все поля</div>
            ) : null}
            <div className={styles.linkContainer}>
                <Link to="/login"
                      className={styles.loginButton}>
                    Назад к входу
                </Link>
            </div>
        </div>
    );
};

export default EnetPhonePasswordRestoreForm;
