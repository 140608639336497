import {ENDPOINTS, LOGS_MESSAGES, SUBMIT_MESSAGES} from "../config";

export const refreshCode = async({
    setErrorMessage,
}) => {
    try {
        let token = localStorage.getItem('validationToken');
        if (!token) {
            token = localStorage.getItem('resetToken')
            if (!token) {
                setErrorMessage(SUBMIT_MESSAGES.maxValidationTimeError);
                return;
            }
        }


        const response = await fetch(ENDPOINTS.userRefreshCode, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 401) {
            localStorage.removeItem('validationToken');
            setErrorMessage(SUBMIT_MESSAGES.maxValidationTimeError);
        } else if (response.status !== 200) {
            localStorage.removeItem('validationToken');
            setErrorMessage(SUBMIT_MESSAGES.serverError);
            console.log(response);
        }
    } catch (error) {
        console.error(LOGS_MESSAGES.requestError, error);
        setErrorMessage(SUBMIT_MESSAGES.serverError);
    }
};
