export const VALIDATION_MESSAGES = {
    "fieldIsEmpty": "Заполните это поле",
    "notFullPhone": "Введите номер телефона полностью",
    "notFullUserCode": "Введите код полностью",
    "tooShortPassword": "Минимальная длина пароля - 6 символов",
    "notSamePasswords": "Пароли не совпадают",
    "badName": "Допустимы только буквы и дефис",
    "badEmail": "Неверный формат email",
}
export const SUBMIT_MESSAGES = {
    "serverError": "Что-то пошло не так...",
    "invalidLogin": "Неверный логин или пароль",
    "duplicateUser": "Данный номер телефона уже зарегистрирован",
    "invalidCode": "Неверный код",
    "maxValidationTimeError": "Максимальное время на подтверждение номера вышло, вернитесь назад",
    "notExistNumber": "Указанный номер телефона не существует",
    "success": "Успех!"
}
export const LOGS_MESSAGES = {
    "requestError": "Ошибка при отправке запроса:",
}

export const PHONE_MASK = `+7 (\\999) 999 99-99`
export const CODE_MASK = `999-999`
export const NAME_REGEX = /^[a-zA-Zа-яА-ЯёЁ]+(?:-[a-zA-Zа-яА-ЯёЁ]+)?$/;
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const API_URL = process.env.REACT_APP_API_URL;

export const STORE_URL = process.env.REACT_APP_STORE_URL;
export const USER_URL = API_URL + '/user';
export const LINK_URL = API_URL + '/link';
export const BITRIX_URL = API_URL + '/bitrix';

export const ENDPOINTS = {
    "userLogin": USER_URL + '/login',
    "userRegister": USER_URL + '/register',
    "userValidate": USER_URL + '/validate',
    "userRefreshCode": USER_URL + '/refresh-code',
    "userGetResetToken": USER_URL + '/get-password-reset-token',
    "userResetPassword": USER_URL + '/reset-password',
    "userLogout": USER_URL + '/logout',
    "userProfile": USER_URL + '/profile',
    "bitrixGetDeals": BITRIX_URL + "/get-deals",
    "getLink": LINK_URL + "/get-link",
}

