import {ENDPOINTS} from "../config";


export const getLink = async (productName) => {
    try {
        const response = await fetch(`${ENDPOINTS.getLink}?product_name=${productName}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            return await response.json();
        } else if (response.status === 404) {
            return null;
        } else {
            console.log('Что-то идет не так при получении ссылки на товар...');
            console.log(response);
            return null
        }
    } catch (error) {
        console.error('Ошибка при отправке запроса:', error);
        return null;
    }
};
