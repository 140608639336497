import React, {useEffect, useState} from 'react';
import styles from './RegistrationForm.module.css';

import {Link, useNavigate} from 'react-router-dom';
import InputMask from "react-input-mask";

import {getUser} from "../../utils/getUser";
import {VALIDATION_MESSAGES, PHONE_MASK} from "../../config";
import {
    handleEmailChange,
    handleFirstNameChange, handleLastNameChange,
    handlePasswordChange,
    handlePasswordRepeatChange,
    handlePhoneNumberChange
} from "../../handlers/UserFieldsHandlers";
import {handleRegisterClick} from "../../handlers/ButtonSubmitHandlers";

const RegistrationForm = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [isValidPasswordRepeat, setIsValidPasswordRepeat] = useState(true);
    const [isValidFirstName, setIsValidFirstName] = useState(true);
    const [isValidLastName, setIsValidLastName] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        getUser()
            .then((user) => {
                if (user) {
                    navigate('/');
                }
            })
    }, [navigate]);

    return (
        <div className={styles.registerForm}>
            <h2>Регистрация</h2>
            <div className={styles.formGroup}>
                <label htmlFor="firstName">Имя:</label>
                <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={firstName}
                    placeholder="Ваше имя"
                    onChange={(e) => handleFirstNameChange(
                    {
                            event: e,
                            setFirstName: setFirstName,
                            setIsValidFirstName: setIsValidFirstName,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                />
                {!isValidFirstName && (
                    <div className={styles.errorMessage}>{VALIDATION_MESSAGES.badName}</div>
                )}
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="lastName">Фамилия:</label>
                <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={lastName}
                    placeholder="Ваша фамилия"
                    onChange={(e) => handleLastNameChange(
                        {
                            event: e,
                            setLastName: setLastName,
                            setIsValidLastName: setIsValidLastName,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                />
                {!isValidLastName && (
                    <div className={styles.errorMessage}>{VALIDATION_MESSAGES.badName}</div>
                )}
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="email">Почта:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="example@example.com"
                    onChange={(e) => handleEmailChange(
                        {
                            event: e,
                            setEmail: setEmail,
                            setIsValidEmail: setIsValidEmail,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                    className={!isValidEmail ? styles.invalidInput : ''}
                />
                {!isValidEmail && (
                    <div className={styles.errorMessage}>{VALIDATION_MESSAGES.badEmail}</div>
                )}
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="phoneNumber">Номер телефона:</label>
                <InputMask
                    mask={PHONE_MASK}
                    alwaysShowMask={true}
                    input={phoneNumber}
                    onChange={(e) => handlePhoneNumberChange(
                        {
                            event: e,
                            setPhoneNumber: setPhoneNumber,
                            setIsValidPhoneNumber: setIsValidPhoneNumber,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                />
                {!isValidPhoneNumber &&
                    <div className={styles.errorMessage}>
                        {VALIDATION_MESSAGES.notFullPhone}
                    </div>
                }
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="password">Пароль:</label>
                <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    value={password}
                    placeholder="Ваш пароль"
                    onChange={(e) => handlePasswordChange(
                        {
                            event: e,
                            setPassword: setPassword,
                            passwordRepeat: passwordRepeat,
                            setIsValidPassword: setIsValidPassword,
                            setIsValidPasswordRepeat: setIsValidPasswordRepeat,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                />
                <button
                    className={styles.togglePasswordButton}
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label={showPassword ? 'Скрыть пароль' : 'Показать пароль'}
                >
                    {showPassword ? '🙈' : '👁️'}
                </button>
                {!isValidPassword &&
                    <div className={styles.errorMessage}>
                        {VALIDATION_MESSAGES.tooShortPassword}
                    </div>
                }
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="passwordRepeat">Повторите пароль:</label>
                <input
                    type={showPasswordRepeat ? 'text' : 'password'}
                    id="passwordRepeat"
                    name="passwordRepeat"
                    value={passwordRepeat}
                    placeholder="Повторите ваш пароль"
                    onChange={(e) => handlePasswordRepeatChange(
                        {
                            event: e,
                            password: password,
                            setPasswordRepeat: setPasswordRepeat,
                            setIsValidPasswordRepeat: setIsValidPasswordRepeat,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                />
                <button
                    className={styles.togglePasswordButton}
                    onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                    aria-label={showPasswordRepeat ? 'Скрыть пароль' : 'Показать пароль'}
                >
                    {showPasswordRepeat ? '🙈' : '👁️'}
                </button>
                {!isValidPasswordRepeat &&
                    <div className={styles.errorMessage}>
                        {VALIDATION_MESSAGES.notSamePasswords}
                    </div>
                }
            </div>
            <button
                className={styles.registerButton}
                onClick={() => handleRegisterClick({
                    phoneNumber: phoneNumber,
                    password: password,
                    email: email,
                    lastName: lastName,
                    firstName: firstName,
                    setPassword: setPassword,
                    setPasswordRepeat: setPasswordRepeat,
                    setErrorMessage: setErrorMessage,
                    navigate: navigate,
                })}
                disabled={
                    !(
                        firstName &&
                        lastName &&
                        email &&
                        phoneNumber &&
                        password &&
                        isValidPassword &&
                        isValidPasswordRepeat &&
                        isValidEmail &&
                        isValidPhoneNumber &&
                        isValidFirstName &&
                        isValidLastName
                    )
                }
            >
                Зарегистрироваться
            </button>
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
            {
                !(
                    firstName &&
                    lastName &&
                    email &&
                    phoneNumber &&
                    password
                ) &&
                <div className={styles.errorMessage}>Заполните все поля</div>
            }
            <div className={styles.linkContainer}>
                <Link to="/login"
                      className={styles.loginButton}>
                    Войти
                </Link>
            </div>
        </div>
    );
};

export default RegistrationForm;
