import React, {useEffect, useState} from 'react';
import OrderCard from '../../components/OrderCard/OrderCard';

import styles from "./OrderPage.module.css"
import {ENDPOINTS} from "../../config";
import {useNavigate} from "react-router-dom";
import Header from "../../components/Header/Header";
import logo from "../../images/logo.png";

const OrderPage = () => {
    const [deals, setDeals] = useState([]);
    const [user, setUser] = useState([]);
    const token = localStorage.getItem("accessToken");

    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }

        fetch(ENDPOINTS.userProfile, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 401) {
                    localStorage.removeItem("accessToken");
                    navigate('/login');
                } else {
                    return response.json();
                }
            })
            .then(data => setUser(data))
            .catch(error => console.error('Error fetching user:', error));

        fetch(ENDPOINTS.bitrixGetDeals, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    navigate('/login');
                } else {
                    return response.json();
                }
            })
           .then(data => setDeals(data.deals))
           .catch(error => console.error('Error fetching deals:', error));
    }, []);

    return (
        <div className="order-page">
            <Header
                userName={user.first_name}
            />
            <img width="300vw" src={logo} alt="Logo" />
            <h2>Заказы</h2>
            { (deals !== undefined) && (deals.length > 0) ? deals.map((deal, index) => (
                <OrderCard key={index} deal={deal} />
            )) : <h1 className={styles.notfoundtext}>Здесь появятся ваши заказы</h1> }
        </div>
    );
};

export default OrderPage;