import React from 'react';
import styles from './Header.module.css';

import {handleLogoutButtonClick} from "../../handlers/ButtonSubmitHandlers";
import {Link, useNavigate} from "react-router-dom";
import {STORE_URL} from "../../config";
import logout from "../../images/logout.png";

const Header = ({ userName }) => {
    const navigate = useNavigate();

    return (
        <div className={styles.header}>
            <button onClick={() => window.location.href = STORE_URL}
                    className={styles.storeButton}>
                Сделать заказ
            </button>
            <div className={styles.userBlock}>
                <div className={styles.userInfo}>
                    <Link to="/edit-profile"
                          className={styles.profileLink}>
                        {userName}
                    </Link>
                </div>
                <button onClick={() => handleLogoutButtonClick({
                    navigate: navigate
                })} className={styles.logoutButton}>
                    <img width="20vw" src={logout} alt="Logout" />
                </button>
            </div>
        </div>
    );
};

export default Header;