import React, {useEffect, useState} from 'react';
import styles from './PasswordResoreForm.module.css';

import {Link, useNavigate} from 'react-router-dom';
import InputMask from "react-input-mask";

import {getUser} from "../../utils/getUser";
import {VALIDATION_MESSAGES, CODE_MASK} from "../../config";
import {handleCodeChange, handlePasswordChange, handlePasswordRepeatChange} from "../../handlers/UserFieldsHandlers";
import {handlePasswordRestoreButtonClick} from "../../handlers/ButtonSubmitHandlers";
import {refreshCode} from "../../utils/refreshCode";
import {formatTimeCountdown} from "../../utils/dateTimeFormatting";

const PasswordRestoreForm = () => {
    const [userCode, setUserCode] = useState('');
    const [isValidCode, setIsValidCode] = useState(true);
    const [newPassword, setNewPassword] = useState('');
    const [isValidNewPassword, setIsValidNewPassword] = useState(true);
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
    const [isValidNewPasswordRepeat, setIsValidNewPasswordRepeat] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [countdown, setCountdown] = useState(300);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

    const resetToken = localStorage.getItem('resetToken');
    const navigate = useNavigate();

    useEffect(() => {
        getUser()
            .then((user) => {
                if (user) {
                    navigate('/');
                } else if (!resetToken) {
                    navigate('/enter-phone');
                }
                else {
                    refreshCode({
                        setErrorMessage: setErrorMessage,
                    });
                }
            })
    }, [navigate]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        return () => {
            clearInterval(timer);
            if (countdown === 0) {
                window.location.reload();
            }
        };
    }, [countdown]);

    return (
        <div className={styles.passwordResetForm}>
            <h2>Восстановление пароля</h2>
            <div className={styles.countdown}>
                Код обновится через: {formatTimeCountdown(countdown)}
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="userCode">Код:</label>
                <InputMask
                    mask={CODE_MASK}
                    alwaysShowMask={true}
                    input={userCode}
                    className={!isValidCode ? styles.invalidInput : undefined}
                    autocomplete={"one-time-code"}
                    onChange={(e) => handleCodeChange(
                        {
                            event: e,
                            setUserCode: setUserCode,
                            setIsValidUserCode: setIsValidCode,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                />
                {!isValidCode &&
                    <div className={styles.errorMessage}>
                        {VALIDATION_MESSAGES.notFullUserCode}
                    </div>
                }
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="password">Пароль:</label>
                <input
                    type={showPassword ? 'text' : 'password'}
                    id="new_password"
                    name="new_password"
                    value={newPassword}
                    placeholder="Ваш новый пароль"
                    onChange={(e) => handlePasswordChange(
                        {
                            event: e,
                            setPassword: setNewPassword,
                            passwordRepeat: newPasswordRepeat,
                            setIsValidPassword: setIsValidNewPassword,
                            setIsValidPasswordRepeat: setIsValidNewPasswordRepeat,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                />
                <button
                    className={styles.togglePasswordButton}
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label={showPassword ? 'Скрыть пароль' : 'Показать пароль'}
                >
                    {showPassword ? '🙈' : '👁️'}
                </button>
                {!isValidNewPassword &&
                    <div className={styles.errorMessage}>
                        {VALIDATION_MESSAGES.tooShortPassword}
                    </div>
                }
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="passwordRepeat">Повторите пароль:</label>
                <input
                    type={showPasswordRepeat ? 'text' : 'password'}
                    id="password_new_repeat"
                    name="passwordNewRepeat"
                    value={newPasswordRepeat}
                    placeholder="Повторите новый ваш пароль"
                    onChange={(e) => handlePasswordRepeatChange(
                        {
                            event: e,
                            password: newPassword,
                            setPasswordRepeat: setNewPasswordRepeat,
                            setIsValidPasswordRepeat: setIsValidNewPasswordRepeat,
                            setErrorMessage: setErrorMessage,
                        }
                    )}
                />
                <button
                    className={styles.togglePasswordButton}
                    onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                    aria-label={showPasswordRepeat ? 'Скрыть пароль' : 'Показать пароль'}
                >
                    {showPasswordRepeat ? '🙈' : '👁️'}
                </button>
                {!isValidNewPasswordRepeat &&
                    <div className={styles.errorMessage}>
                        {VALIDATION_MESSAGES.notSamePasswords}
                    </div>
                }
            </div>
            <button
                className={styles.passwordResetButton}
                onClick={() => handlePasswordRestoreButtonClick({
                    code: userCode,
                    setCode: setUserCode,
                    newPassword: newPassword,
                    setErrorMessage: setErrorMessage,
                    navigate: navigate,
                })}
                disabled={!(
                    userCode &&
                    newPassword &&
                    newPasswordRepeat &&
                    isValidCode &&
                    isValidNewPassword &&
                    isValidNewPasswordRepeat
                )}
            >
                Подтвердить
            </button>
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
            {!(
                userCode &&
                newPassword &&
                newPasswordRepeat
            ) ? (
                <div className={styles.errorMessage}>Заполните все поля</div>
            ) : null}
            <div className={styles.linkContainer}>
                <Link to="/login"
                      className={styles.loginButton}>
                    Назад ко входу
                </Link>
            </div>
        </div>
    );
};

export default PasswordRestoreForm;
