import {ENDPOINTS, LOGS_MESSAGES, SUBMIT_MESSAGES, STORE_URL} from "../config";

export const handleLoginClick = async ({
                                            phoneNumber,
                                            password,
                                            setErrorMessage,
                                            setPassword,
                                            navigate
                                       }) => {
    try {
        const response = await fetch(ENDPOINTS.userLogin, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                phone: phoneNumber,
                password: password }),
        });

        if (response.status === 200) {
            const data = await response.json();
            localStorage.setItem('accessToken', data.access_token);
            window.location.href = STORE_URL + '?accessToken=' + data.access_token;
        } else if (response.status === 400) {
            setErrorMessage(SUBMIT_MESSAGES.invalidLogin);
            setPassword('');
        } else {
            setErrorMessage(SUBMIT_MESSAGES.serverError);
            console.log(response);
        }
    } catch (error) {
        console.error(LOGS_MESSAGES.requestError, error);
        setErrorMessage(SUBMIT_MESSAGES.serverError);
        setPassword('');
    }
};


export const handleRegisterClick = async ({
                                              phoneNumber,
                                              password,
                                              email,
                                              lastName,
                                              firstName,
                                              setPassword,
                                              setPasswordRepeat,
                                              setErrorMessage,
                                              navigate
                                          }) => {
    try {
        const response = await fetch(ENDPOINTS.userRegister, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                phone: phoneNumber,
                password: password,
                email: email,
                first_name: firstName,
                last_name: lastName
            }),
        });

        if (response.status === 200) {
            const data = await response.json();
            localStorage.setItem('validationToken', data.validation_token)
            navigate('/validate-phone');
        } else if (response.status === 400) {
            setErrorMessage(SUBMIT_MESSAGES.duplicateUser);
            setPassword('');
            setPasswordRepeat('');
        } else {
            setErrorMessage(SUBMIT_MESSAGES.serverError);
            console.log(response);
        }
    } catch (error) {
        console.error(LOGS_MESSAGES.requestError, error);
        setErrorMessage(SUBMIT_MESSAGES.serverError);
        setPassword('');
        setPasswordRepeat('');
    }
};

export const handleCodeButtonClick = async({
    userCode,
    setUserCode,
    setErrorMessage,
    navigate
}) => {
    try {
        const token = localStorage.getItem('validationToken');
        if (!token) {
            setErrorMessage(SUBMIT_MESSAGES.maxValidationTimeError);
            return;
        }
        const response = await fetch(ENDPOINTS.userValidate, {
            method: 'POST',
            body: JSON.stringify({
                'code': userCode,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            const data = await response.json();
            localStorage.removeItem('validationToken');
            localStorage.setItem('accessToken', data.access_token);
            window.location.href = STORE_URL + '?accessToken=' + data.access_token;
        } else if (response.status === 400) {
            setUserCode('');
            setErrorMessage(SUBMIT_MESSAGES.invalidCode);
        }
        else if (response.status === 401) {
            localStorage.removeItem('validationToken');
            setErrorMessage(SUBMIT_MESSAGES.maxValidationTimeError);
        }
        else {
            localStorage.removeItem('validationToken');
            setErrorMessage(SUBMIT_MESSAGES.serverError);
            console.log(response);
        }
    } catch (error) {
        console.error(LOGS_MESSAGES.requestError, error);
        setErrorMessage(SUBMIT_MESSAGES.serverError);
        setUserCode('');
    }
};

export const handlePhoneRestorePasswordButtonClick = async({
                                                phone,
                                                setErrorMessage,
                                                navigate,
                                           }) => {
    try {
        const response = await fetch(ENDPOINTS.userGetResetToken, {
            method: 'POST',
            body: JSON.stringify({
                'phone': phone,
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (response.status === 200) {
            const data = await response.json();
            localStorage.setItem('resetToken', data.reset_token);
            navigate('/reset-password');
        } else if (response.status === 404) {
            setErrorMessage(SUBMIT_MESSAGES.notExistNumber);
        }
        else {
            setErrorMessage(SUBMIT_MESSAGES.serverError);
            console.log(response);
        }
    } catch (error) {
        console.error(LOGS_MESSAGES.requestError, error);
        setErrorMessage(SUBMIT_MESSAGES.serverError);
    }
};

export const handlePasswordRestoreButtonClick = async({
                                                         newPassword,
                                                         code,
                                                         setCode,
                                                         setErrorMessage,
                                                         navigate,
                                                     }) => {
    try {
        const token = localStorage.getItem('resetToken');
        if (!token) {
            setErrorMessage(SUBMIT_MESSAGES.maxValidationTimeError);
            return;
        }

        const response = await fetch(ENDPOINTS.userResetPassword, {
            method: 'POST',
            body: JSON.stringify({
                'code': code,
                'password': newPassword,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        if (response.status === 200) {
            localStorage.removeItem('resetToken')
            navigate('/login');
        } else if (response.status === 400) {
            setCode('');
            setErrorMessage(SUBMIT_MESSAGES.invalidCode);
        }
        else if (response.status === 401) {
            localStorage.removeItem('resetToken')
            setErrorMessage(SUBMIT_MESSAGES.maxValidationTimeError);
        }
        else {
            localStorage.removeItem('resetToken');
            setErrorMessage(SUBMIT_MESSAGES.serverError);
            console.log(response);
        }
    } catch (error) {
        console.error(LOGS_MESSAGES.requestError, error);
        setErrorMessage(SUBMIT_MESSAGES.serverError);
    }
};

export const handleLogoutButtonClick = async({navigate}) => {
    try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            return;
        }

        const response = await fetch(ENDPOINTS.userLogout, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        if (response.status === 200) {
            localStorage.removeItem('accessToken');
            navigate('/login');
        }
    } catch (error) {
        console.error(LOGS_MESSAGES.requestError, error);
    }
};

export const handleEditClick = async ({
                                              email,
                                              lastName,
                                              firstName,
                                              setErrorMessage,
                                              navigate
                                          }) => {
    try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            navigate('/login');
            return;
        }

        const response = await fetch(ENDPOINTS.userProfile, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                email: email,
                first_name: firstName,
                last_name: lastName
            }),
        });

        if (response.status === 200) {
            setErrorMessage(SUBMIT_MESSAGES.success);
        } else if (response.status === 401) {
            localStorage.removeItem('accessToken');
            navigate('/login');
        } else {
            setErrorMessage(SUBMIT_MESSAGES.serverError);
            console.log(response);
        }
    } catch (error) {
        console.error(LOGS_MESSAGES.requestError, error);
        setErrorMessage(SUBMIT_MESSAGES.serverError);
    }
};