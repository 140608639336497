import React, { useEffect, useState } from 'react';
import styles from './LoginForm.module.css';
import { Link, useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { getUser } from '../../utils/getUser';
import { VALIDATION_MESSAGES, PHONE_MASK } from '../../config';
import {
    handlePasswordChange,
    handlePhoneNumberChange
} from '../../handlers/UserFieldsHandlers';
import { handleLoginClick } from '../../handlers/ButtonSubmitHandlers';

const LoginForm = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getUser().then((user) => {
            if (user) {
                navigate('/');
            }
        });
    }, [navigate]);

    return (
        <div className={styles.loginForm}>
            <h2>Вход</h2>
            <div className={styles.formGroup}>
                <label htmlFor="phoneNumber">Номер телефона:</label>
                <InputMask
                    mask={PHONE_MASK}
                    alwaysShowMask={true}
                    input={phoneNumber}
                    onChange={(e) =>
                        handlePhoneNumberChange({
                            event: e,
                            setPhoneNumber: setPhoneNumber,
                            setIsValidPhoneNumber: setIsValidPhoneNumber,
                            setErrorMessage: setErrorMessage
                        })
                    }
                />
                {!isValidPhoneNumber && (
                    <div className={styles.errorMessage}>
                        {VALIDATION_MESSAGES.notFullPhone}
                    </div>
                )}
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="password">Пароль:</label>
                <div className={styles.passwordInput}>
                    <input
                        type={showPassword ? 'text' : 'password'} // Toggle password visibility based on showPassword state
                        id="password"
                        name="password"
                        value={password}
                        placeholder="Пароль"
                        onChange={(e) =>
                            handlePasswordChange({
                                event: e,
                                setPassword: setPassword,
                                setIsValidPassword: setIsValidPassword,
                                setErrorMessage: setErrorMessage
                            })
                        }
                    />
                    <button
                        className={styles.togglePasswordButton}
                        onClick={() => setShowPassword(!showPassword)}
                        aria-label={showPassword ? 'Скрыть пароль' : 'Показать пароль'}
                    >
                        {showPassword ? '🙈' : '👁️'}
                    </button>
                </div>
                {!isValidPassword && (
                    <div className={styles.errorMessage}>
                        {VALIDATION_MESSAGES.tooShortPassword}
                    </div>
                )}
            </div>
            <button
                className={styles.loginButton}
                onClick={() =>
                    handleLoginClick({
                        phoneNumber: phoneNumber,
                        password: password,
                        setPassword: setPassword,
                        setErrorMessage: setErrorMessage,
                        navigate: navigate
                    })
                }
                disabled={
                    !(phoneNumber && password && isValidPassword && isValidPhoneNumber)
                }
            >
                Войти
            </button>
            {errorMessage && (
                <div className={styles.errorMessage}>{errorMessage}</div>
            )}
            {!phoneNumber || !password ? (
                <div className={styles.errorMessage}>Заполните все поля</div>
            ) : null}
            <div className={styles.linkContainer}>
                <Link to="/registration" className={styles.registerButton}>
                    Зарегистрироваться
                </Link>
            </div>
            <div className={styles.linkContainer}>
                <Link to="/enter-phone" className={styles.passwordRecoveryLink}>
                    Забыли пароль?
                </Link>
            </div>
            <div className={styles.linkContainer}>
                <Link to="https://mixflowers.ru/" className={styles.passwordRecoveryLink}>
                    Назад на сайт
                </Link>
            </div>
        </div>
    );
};

export default LoginForm;
