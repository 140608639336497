import React from 'react';
import EnterCodeForm from '../../components/EnterCodeForm/EnterCodeForm';
import {useNavigate} from "react-router-dom";
import logo from "../../images/logo.png";

const EnterCodePage = () => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    if (token) {
        navigate('/profile');
    }

    return (
        <div className="container window">
            <img width="300vw" src={logo} alt="Logo" />
            <EnterCodeForm />
        </div>
    );
}

export default EnterCodePage;
