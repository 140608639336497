import {EMAIL_REGEX, NAME_REGEX} from "../config";

export const handleFirstNameChange = ({event,
                                       setFirstName,
                                       setIsValidFirstName,
                                       setErrorMessage}) => {
    const input = event.target.value;
    setFirstName(input);

    setIsValidFirstName(NAME_REGEX.test(input));
    setErrorMessage('');
};

export const handleLastNameChange = ({event,
                                      setLastName,
                                      setIsValidLastName,
                                      setErrorMessage}) => {
    const input = event.target.value;
    setLastName(input);

    setIsValidLastName(NAME_REGEX.test(input));
    setErrorMessage('');
};

export const handleEmailChange = ({event,
                                   setEmail,
                                   setIsValidEmail,
                                   setErrorMessage}) => {
    const input = event.target.value;
    setEmail(input);

    setIsValidEmail(EMAIL_REGEX.test(input));
    setErrorMessage('');
};

export const handlePhoneNumberChange = ({event,
                                         setPhoneNumber,
                                         setIsValidPhoneNumber,
                                         setErrorMessage}) => {
    const input = event.target.value;
    setPhoneNumber(input);

    if (!input.includes("_")) {
        setIsValidPhoneNumber(true);
    } else {
        setIsValidPhoneNumber(false);
    }

    setErrorMessage("");
};

export const handlePasswordChange = ({event,
                                      setPassword,
                                      passwordRepeat,
                                      setIsValidPassword,
                                      setIsValidPasswordRepeat,
                                      setErrorMessage}) => {
    const input = event.target.value;
    setPassword(event.target.value);

    if (input.length >= 6) {
        setIsValidPassword(true);
    } else {
        setIsValidPassword(false);
    }

    if (setIsValidPasswordRepeat) {
        if (input === passwordRepeat) {
            setIsValidPasswordRepeat(true);
        } else {
            setIsValidPasswordRepeat(false);
        }
    }

    setErrorMessage("");
};

export const handlePasswordRepeatChange = ({event,
                                            password,
                                            setPasswordRepeat,
                                            setIsValidPasswordRepeat,
                                            setErrorMessage}) => {
    const input = event.target.value;
    setPasswordRepeat(event.target.value);

    if (input === password) {
        setIsValidPasswordRepeat(true);
    } else {
        setIsValidPasswordRepeat(false);
    }

    setErrorMessage("");
};

export const handleCodeChange = ({event,
                                  setUserCode,
                                  setIsValidUserCode,
                                  setErrorMessage}) => {
    const input = event.target.value;
    setUserCode(input);

    if (!input.includes("_")) {
        setIsValidUserCode(true);
    } else {
        setIsValidUserCode(false);
    }

    setErrorMessage("");
};
