import React, { useState } from 'react';
import Routes from "./Routes";
import './App.css';
import bg1 from "./images/bg01.jpg";
import bg2 from "./images/bg02.jpg";

function App() {
    const [loading, setLoading] = useState(false);

    return (
        <div>
            {loading && <p>Loading...</p>}
            <div
                className="App"
                style={{ display: loading ? 'none' : 'block' }}
            >
                <img className="bg bg1" src={bg1} alt="bg1" />
                <img className="bg bg2" src={bg2} alt="bg2" />
                <Routes
                    setLoadingHeader={setLoading}
                />
            </div>
        </div>
    );
}

export default App;
