import {USER_URL} from "../config";


export const getUser = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
        return null;
    }

    try {
        const response = await fetch(USER_URL + '/profile', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            return await response.json();
        } else if (response.status === 401 ||
                    response.status === 404) {
            localStorage.removeItem('accessToken');
            return null;
        } else {
            console.log('Что-то идет не так при получении пользователя...');
            console.log(response);
            return null
        }
    } catch (error) {
        console.error('Ошибка при отправке запроса:', error);
        return null;
    }
};
