import React, { useEffect, useState } from 'react';
import styles from './OrderCard.module.css';
import { getLink } from '../../utils/getLink';

const OrderCard = ({ deal }) => {
    const [productLinks, setProductLinks] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const links = await Promise.all(
                deal.products.replace(/&quot;/g, '"').split(';').map(async (product) => {
                    const match = product.match(/^(.*)=(\d+) x (\d+) = (\d+)$/);
                    if (match) {
                        const itemName = match[1].trim();
                        const data = await getLinkForItem(itemName);
                        return data !== null ? { name: itemName, link: data.url } : { name: itemName, link: null };
                    } else {
                        return { name: product, link: null };
                    }
                })
            );
            setProductLinks(links);
        };
        fetchData();
    }, [deal.products]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('ru-RU', options);
    };

    const getLinkForItem = async (productName) => {
        return await getLink(productName);
    };

    const parseProduct = (product, index) => {
        const productData = productLinks[index];
        const { link } = productData || {};
        const match = product.match(/^(.*)=(\d+) x (\d+) = (\d+)$/);
        if (match) {
            const itemName = match[1].trim();
            const quantity = parseInt(match[3], 10);
            const unitPrice = parseInt(match[2], 10);
            const total = parseInt(match[4], 10);
            return link ? (
                <a className={styles.link} href={link}>{`${index + 1}. ${itemName} (${quantity} шт.) ${unitPrice} руб. = ${total} руб.`}</a>
            ) : (
                `${index + 1}. ${itemName} (${quantity} шт.) ${unitPrice} руб. = ${total} руб.`
            );
        } else {
            return product;
        }
    };

    const parseTitle = (is_self_order) => {
        if (is_self_order === 'yes') {
            return 'Заказ для Вас';
        } else if (deal.name_recipient) {
            return `Заказ для ${deal.name_recipient}`;
        } else {
            return 'Имя получателя не указано';
        }
    };

    const renderTimeline = () => {
        const stages = [
            { stage: 'NEW', label: 'Новый' },
            // { stage: 'UC_RU0S44', label: 'Принят' },
            { stage: 'UC_6ZMLH5', label: 'Оплачен' },
            { stage: 'WON', label: 'Доставлен' }
        ];

        const currentStageIndex = stages.findIndex((stage) => stage.stage === deal.stage);

        return (
            <div>
                <div className={styles.timeline}>
                    {stages.map((stage, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && (
                                <div className={index <= currentStageIndex ? styles.timelineConnectorCompleted : styles.timelineConnector}></div>
                            )}
                            <div>
                <span
                    className={`${styles.circle} ${index < currentStageIndex ? styles.completedStage : ''} ${
                        index === currentStageIndex ? styles.currentStage : ''
                    }`}
                ></span>
                                <p className={styles.stageLabel}>{stage.label}</p>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className={styles.orderCard}>
            <h3>{parseTitle(deal.is_self_order)}</h3>
            <p>
                <strong>Статус заказа:</strong>
            </p>
            {renderTimeline()}
            <p>
                <strong>Дата заказа:</strong> {formatDate(deal.begin_date)}
            </p>
            <p>
                <strong>Район доставки:</strong> {deal.delivery_type.replace(/=[\d.]+/, '')}
            </p>
            <p>
                <strong>Адрес доставки:</strong> {deal.street}, {deal.house}, {deal.flat}
            </p>
            <p>
                <strong>Время доставки:</strong> {deal.time}
            </p>
            <p>
                <strong>Дата доставки:</strong> {deal.date}
            </p>
            <p>
                <strong>Товары:</strong>
            </p>
            <p className={styles.cartElem}>
                {deal.products.replace(/&quot;/g, '"').split(';').map((product, index) => (
                    <div key={index}>
                        <p className={styles.cartElem}>{parseProduct(product, index)}</p>
                        {deal.products.replace(/&quot;/g, '"').split(';').length !== index + 1 ? <hr /> : null}
                    </div>
                ))}
            </p>
            <p>
                <strong>Стоимость доставки:</strong> {deal.delivery_cost} {deal.currency === 'RUB' ? 'руб.' : deal.currency}
            </p>
            <p>
                <strong>Сумма:</strong> {deal.opportunity} {deal.currency === 'RUB' ? 'руб.' : deal.currency}
            </p>
        </div>
    );
};

export default OrderCard;
